/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import strapiCloud from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=2bf8d3ba";
import graphql from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-graphql_strapi-admin.js?v=2bf8d3ba";
import usersPermissions from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=2bf8d3ba";
import configSync from "/admin/node_modules/.strapi/vite/deps/strapi-plugin-config-sync_strapi-admin.js?v=2bf8d3ba";
import { renderAdmin } from "/admin/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=2bf8d3ba";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    "strapi-cloud": strapiCloud,
    graphql: graphql,
    "users-permissions": usersPermissions,
    "config-sync": configSync,
  },
});
